import React from 'react';
import PropTypes from 'prop-types';
import { each, replace } from 'lodash';
import { Route } from 'react-router-dom';

export const appNamedRoutes = {
  root: '/',
  iwu_root: '/iwu',
  logout: '/logout',
  grade: '/:grade',
  guidebook: '/:grade/:guidebook',
  section: '/:grade/:guidebook/:section',
  lesson: '/:grade/:guidebook/:section/:lesson',
  activity: '/:grade/:guidebook/:section/:lesson/:activity',
};

export const staticRoutes = {
  about: '/about',
  about_iwu: '/about-iwu',
  privacy: '/privacy-policy',
  terms: '/terms',
  literacy_toolbox: '/literacy-toolbox',
};

export const ODELL_HIERARCHY = [
  'subject',
  'grade',
  'guidebook',
  'section',
  'lesson',
  'activity',
];
export const ODELL_BREADCRUMB_HIERARCHY = ['sections', 'lessons', 'activities'];

export const cleanedSlug = slug => {
  return replace(slug, /(ela)|(\/iwu)|(iwu)/, '');
};

export const paramsToSlug = params => {
  let slug = '';
  each(ODELL_HIERARCHY, h => {
    if (params[h] && params[h] !== 'ela') slug += `/${params[h]}`;
    else return;
  });
  return slug;
};

export const linkWithParams = (routeType, params = {}) => {
  let path = appNamedRoutes[routeType];
  each(params, (v, k) => {
    path = replace(path, `:${k}`, v);
  });

  if (params['subject'] !== 'ela') {
    path = `/${params['subject']}${path}`;
  }

  return path;
};

export const isStudentSubdomain = devise => {
  const regEx = new RegExp(`^${devise.subdomain}`);
  return regEx.test(window.location.host);
};

export const studentsPath = devise => devise.studentsPath;

export const isIwuFor = path => {
  const regEx = new RegExp(`^/(iwu|about-iwu)`);
  return regEx.test(path);
};

export const isIwu = () => isIwuFor(window.location.pathname);

export const studentPath = devise => {
  const urlArray = window.location.host.split('.');
  urlArray[0] = devise.subdomain;
  return (
    window.location.protocol +
    '//' +
    urlArray.join('.') +
    window.location.pathname +
    window.location.search
  );
};

class ExtendedRoute extends React.Component {
  static propTypes = {
    component: PropTypes.elementType.isRequired,
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        /* eslint-disable react/jsx-no-bind */
        render={props => <Component {...props} rest={rest} />}
      />
    );
  }
}

export default ExtendedRoute;
