import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utils/i18n.js.erb';
import { map } from 'lodash';

const LessonAlignments = ({ resource }) => {
  const alignments = data => {
    const rows = map(data, a => {
      return (
        <tr key={`alg-${a.id}`}>
          <td className="text-center">{a.abbr}</td>
          <td>
            <span>{a.description}</span>
          </td>
        </tr>
      );
    });
    return (
      <div className="u-od-txt--content">
        <table className="o-od-alignments u-od-txt--alignments u-od-margin-bottom--large">
          <thead>
            <tr>
              <th>{I18n.t('ui.table.alignments.header.abbr')}</th>
              <th>{I18n.t('ui.table.alignments.header.description')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  const alignmentsElps = () => {
    return (
      <div className="row u-od-full-width">
        <aside className="o-od-panel__aside">
          <h2>{I18n.t('ui.lesson.alignment.elps_abbr')}</h2>
        </aside>
        <section className="o-od-panel__content-multirow">
          {alignments(resource.alignmentElps)}
        </section>
      </div>
    );
  };
  const alignmentsTeks = () => {
    return (
      <div className="row u-od-full-width">
        <aside className="o-od-panel__aside">
          <h2>{I18n.t('ui.lesson.alignment.teks_abbr_focus')}</h2>
        </aside>
        <section className="o-od-panel__content-multirow">
          {alignments(resource.alignmentTeks)}
        </section>
      </div>
    );
  };
  const alignmentsSupportingTeks = () => {
    if (resource.alignmentSupportingTeks.length !== 0) {
      return (
        <div className="row u-od-full-width">
          <aside className="o-od-panel__aside">
            <h2>{I18n.t('ui.lesson.alignment.teks_abbr_supporting')}</h2>
          </aside>
          <section className="o-od-panel__content-multirow">
            {alignments(resource.alignmentSupportingTeks)}
          </section>
        </div>
      );
    }
  };

  return (
    <div>
      {alignmentsTeks()}
      {alignmentsSupportingTeks()}
      {alignmentsElps()}
    </div>
  );
};

LessonAlignments.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonAlignments;
