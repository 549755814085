const BACK_OFF = 1759;

export default function callWithRetry(body, logInfo, attempt = 0) {
  if (attempt > 0) {
    console.log(`retry attempt ${attempt} (${JSON.stringify(logInfo)})`);
  }

  return new Promise((resolve, reject) => {
    body().then(
      response => {
        resolve(response);
      },
      error => {
        const content =
          error &&
          error.result &&
          error.result.error &&
          error.result.error.errors &&
          error.result.error.errors[0];

        const rateError =
          content &&
          (content.reason === 'userRateLimitExceeded' ||
            content.reason === 'rateLimitExceeded');

        const internalError = content && content.reason === 'internalError';

        if (internalError) {
          console.log(logInfo);
        }

        if ((rateError || internalError) && attempt < 10) {
          setTimeout(() => {
            resolve(callWithRetry(body, logInfo, attempt + 1));
          }, (attempt + 1) * BACK_OFF);
        } else {
          reject(error);
        }
      }
    );
  });
}
