import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import I18n from 'utils/i18n.js.erb';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import LessonCard from '../sections/LessonCard';

const GuidebookOverview = ({ resource }) => {
  let elements;

  if (resource.skipSections) {
    const lessons = map(resource.children, s => (
      <LessonCard key={`sl-${s.id}`} resource={s} />
    ));

    elements = <ol className="u-od-margin-top--large no-bullet">{lessons}</ol>;
  } else {
    const sections = map(resource.children, s => (
      <li
        key={`gs-${s.id}`}
        className="columns small-6 large-4 u-od-margin-bottom--base"
      >
        <Link
          className="o-od-card u-od-cs-bg--dark-purple u-od-custom-link o-od-section u-od-txt--subheader align-middle align-center"
          to={{ pathname: linkWithParams('section', s.hierarchy) }}
        >
          {s.title}
        </Link>
      </li>
    ));

    elements = <ol className="row u-od-margin-top--large">{sections}</ol>;
  }

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t(`ui.guidebook.tabs.overview`)}</h2>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(resource.teaser, 'u-od-txt--content')}
        {elements}
      </section>
    </div>
  );
};

GuidebookOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default GuidebookOverview;
