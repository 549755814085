import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import TabContainer from '../common/TabContainer';

import GuidebookOverview from './GuidebookOverview';
import GuidebookCulminatingTask from './GuidebookCulminatingTask';
import GuidebookTextOverview from './GuidebookTextOverview';
import GuidebookMaterials from './GuidebookMaterials';

const TAB_KEYS = [
  { key: 'overview', component: GuidebookOverview },
  { key: 'culminating_task', component: GuidebookCulminatingTask },
  { key: 'texts', component: GuidebookTextOverview },
  { key: 'materials', component: GuidebookMaterials },
];

const Guidebook = ({ resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'guidebook')
    return null;

  return (
    <TabContainer
      tabKeys={TAB_KEYS}
      type="guidebook"
      cls="small-6 large-3"
      resource={resource}
    />
  );
};

Guidebook.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Guidebook;
