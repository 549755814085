import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import classNames from 'classnames';
import { getSegment } from './ActivityHelpers';
import ActivitySlides from './ActivitySlides';
import ActivitySlidesControls from './ActivitySlidesControls';
import I18n from '../../utils/i18n.js.erb';

class ActivityContent extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    // actions
    loadFullScreen: PropTypes.func.isRequired,
  };

  toggleFullScreen = e => {
    if (e.keyCode === undefined || (e.keyCode !== 9 && !e.keyCode.shiftKey)) {
      // 9 = tab
      this.props.loadFullScreen(!this.props.meta.fullScreen);
    }
  };

  render() {
    const { resource, meta } = this.props;
    const { activity, content } = resource;
    const segmentLength = isArray(content.student.segment)
      ? content.student.segment.length
      : 1;
    const currenSegment = getSegment(content, meta.segmentNumber, 'student');
    const segment = {
      idx: meta.segmentNumber,
      data: currenSegment,
      total: segmentLength,
    };
    const clsBase = classNames('o-od-slide u-od-cs-bg--card-bg-dark', {
      'o-od-slide--base': !meta.fullScreen,
    });
    const clsIcon = classNames(
      'u-od-txt--icon-large u-od-pointer',
      { 'od-icon-expand': !meta.fullScreen },
      { 'od-icon-shrink': meta.fullScreen }
    );

    return (
      <div className={clsBase}>
        <div className="o-od-slide__header u-od-link">
          <i
            role="button"
            tabIndex="0"
            title={
              meta.fullScreen
                ? I18n.t('ui.activity.fullscreen_label.shrink_slide')
                : I18n.t('ui.activity.fullscreen_label.expand_slide')
            }
            className={clsIcon}
            onKeyDown={this.toggleFullScreen}
            onClick={this.toggleFullScreen}
          ></i>
        </div>
        <ActivitySlidesControls segment={segment} />
        <ActivitySlides
          activity={{
            idx: meta.activityNumber,
            purpose: activity.readingPurpose.default,
            nav: resource.nav,
            segmentLength: segmentLength,
          }}
          segment={segment}
          meta={meta}
        />
      </div>
    );
  }
}

export default ActivityContent;
