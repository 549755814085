import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import { map } from 'lodash';
import { Tab, TabPanel, Tabs, TabList } from 'react-tabs';
import I18n from '../../utils/i18n.js.erb';
import { isIwu } from '../../routes';

const TabContainer = ({ tabKeys, type, cls, resource, rest }) => {
  const tabs = renderTabKeys(tabKeys, type, cls);
  const tabPanels = renderTabPanels(tabKeys, resource, rest);

  const searchParams = useLocation().search;
  const match = searchParams.match(/\?tab=(.*)/);
  const defaultTab = parseInt(match ? match[1] : 0);
  const history = useHistory();

  const selectHandler = (index, _lastIndex, _event) => {
    scrollToTop();

    history.replace({
      search: index !== 0 ? `?tab=${index}` : null,
    });

    return true;
  };

  return (
    <Tabs
      defaultIndex={defaultTab}
      className="o-od-tabs"
      /* eslint-disable react/jsx-no-bind */
      onSelect={selectHandler}
      selectedTabClassName="o-od-tabs__tab--selected"
      selectedTabPanelClassName="o-od-tabs__tab-panel--selected"
    >
      <TabList className="o-od-tabs__tab-list row no-bullet">{tabs}</TabList>
      <div>{tabPanels}</div>
    </Tabs>
  );
};

const scrollToTop = () => {
  window.scrollTo({ top: 0 });
  [].forEach.call(
    document.getElementsByClassName('o-od-teacher-notes__body'),
    el => el.scrollTo({ top: 0 })
  );
};

TabContainer.propTypes = {
  tabKeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  cls: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  rest: PropTypes.object,
};

export default TabContainer;

const renderTabPanels = (tabs, resource, rest = {}) =>
  map(tabs, (tab, idx) => {
    const PanelType = tab.component;
    return (
      <TabPanel key={`tabpanel-${idx}`} className="o-od-tabs__tab-panel">
        <PanelType resource={resource} {...rest} />
      </TabPanel>
    );
  });

const renderTabKeys = (tabs, type, cls) =>
  map(tabs, tab => (
    <Tab key={`tab-${tab.key}`} className={`columns ${cls}`} tabIndex="0">
      <div className="o-od-tabs__tab u-od-txt--tab">
        {I18n.t(
          `ui.${type}.tabs.${
            tab.key === 'texts' && type === 'guidebook'
              ? isIwu()
                ? 'iwu.'
                : 'ela.'
              : ''
          }${tab.key}`
        )}
      </div>
    </Tab>
  ));
