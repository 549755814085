import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import TabContainer from '../common/TabContainer';
import { isStudentSubdomain } from '../../routes';

import LessonAlignments from './LessonAlignments';
import LessonOverview from './LessonOverview';
import LessonLearningGoals from './LessonLearningGoals';
import LessonTexts from './LessonTexts';
import LessonMaterials from './LessonMaterials';

const TAB_KEYS = [
  { key: 'overview', component: LessonOverview },
  { key: 'learning_goals', component: LessonLearningGoals },
  { key: 'alignments', component: LessonAlignments },
  { key: 'texts', component: LessonTexts },
  { key: 'materials', component: LessonMaterials },
];

const Lesson = ({ devise, resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'lesson')
    return null;
  const isStudent = isStudentSubdomain(devise);

  return (
    <TabContainer
      tabKeys={TAB_KEYS}
      type="lesson"
      cls="small-6 large-3 o-od-panel-tab--lesson"
      resource={resource}
      rest={{ isStudent }}
    />
  );
};

Lesson.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Lesson;
