import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { backgroundUrlWrap } from '../utils/Formatters';

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const useBodyBackgroundTyped = (backgroundUrls, isSingleMode) => {
  useEffect(() => {
    if (!isEmpty(backgroundUrls))
      if (isSingleMode) {
        document.body.classList.add('u-od-background--singlemode');
        document.body.style.backgroundImage = backgroundUrlWrap(
          backgroundUrls[1]
        );
      } else {
        document.body.classList.remove('u-od-background--singlemode');
        document.body.style.backgroundImage = `${backgroundUrlWrap(
          backgroundUrls[1]
        )}, ${backgroundUrlWrap(backgroundUrls[0])}`;
      }
  }, [backgroundUrls, isSingleMode]);
};
