import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import {
  getMaterialIcon,
  getMaterialTitle,
  materialIcon,
  renderNoMaterials,
} from 'components/common/TextMaterialHelpers';
import I18n from 'utils/i18n.js.erb';
import { rawHtml } from 'utils/Formatters';

const TextsList = ({ textList, resourceType }) => {
  const texts = map(textList, t => {
    // const k = t.accessType;
    const title = t.titleDigitalAccess;
    // NOTE: commenting for now, see https://github.com/learningtapestry/odell-lcms/issues/445, p. 10
    // maybe we will need to revert this
    // k === 'digital-access' || k === 'digital'
    //   ? t.titleDigitalAccess
    //   : t.title;
    const iconTitle = getMaterialTitle(t);
    return (
      <tr key={`tlg-${t.textId}`}>
        <td className="o-od-textlist__icon text-center">
          {materialIcon(getMaterialIcon(t), iconTitle)}
        </td>
        <td>{rawHtml(title, '', 'span')}</td>
      </tr>
    );
  });
  if (isEmpty(texts)) return renderNoMaterials('text', resourceType);
  return (
    <table className="o-od-textlist u-od-txt--materials u-od-margin-bottom--large">
      <thead>
        <tr>
          <th>{I18n.t('ui.table.texts.header.icon')}</th>
          <th>{I18n.t('ui.table.texts.header.title')}</th>
        </tr>
      </thead>
      <tbody>{texts}</tbody>
    </table>
  );
};

TextsList.propTypes = {
  textList: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default TextsList;
