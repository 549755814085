import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import GradeGuidebook from './GradeGuidebook';

const GradeGuidebooks = ({ guidebooks, guidebookType }) => {
  const blocks = map(guidebooks, (g, idx) => (
    <GradeGuidebook
      key={`gb-${g.guidebookType}-${idx}-${g.id}`}
      guidebook={g}
      title={guidebookType === 'foundation' ? g.metadata.unitQuestion : g.title}
    />
  ));
  return (
    <div className="u-od-margin-top--large">
      <h2 className="u-od-txt--upcase">
        {I18n.t(`ui.guidebooks.${guidebookType}`)}
        {guidebooks.length > 1 ? 's' : ''}
      </h2>
      <div className="u-od-hline u-od-margin-top--zero u-od-margin-bottom--gutter"></div>
      <ul className="no-bullet row">{blocks}</ul>
    </div>
  );
};

GradeGuidebooks.propTypes = {
  guidebookType: PropTypes.string.isRequired,
  guidebooks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GradeGuidebooks;
