import React from 'react';
import { get } from 'lodash';

export const rawHtml = (html, cls = '', htmlTag = 'div') => {
  const Tag = htmlTag;
  return <Tag className={cls} dangerouslySetInnerHTML={{ __html: html }} />;
};

export const preloadUrl = resource =>
  `url('${get(resource, 'backgroundUrls[0]', '')}')`;
export const thumbnailUrl = resource =>
  `url('${get(resource, 'thumbnailUrl', '')}')`;
export const thumbnailHoverUrl = resource =>
  `url('${get(resource, 'thumbnailHoverUrl', '')}')`;
export const thumbnailUrlWithPreload = resource =>
  `${thumbnailUrl(resource)}, ${preloadUrl(resource)}`;
export const thumbnailHoverUrlWithPreload = resource =>
  `${thumbnailHoverUrl(resource)}, ${preloadUrl(resource)}`;

export const backgroundUrlWrap = url => `url('${url}')`;
