if (typeof BULK_DOWNLOADER_CREDENTIALS === 'undefined') {
  window.BULK_DOWNLOADER_CREDENTIALS = {
    APP_ID: process.env.APP_ID,
    API_KEY: process.env.API_KEY,
    CLIENT_ID: process.env.CLIENT_ID,
    ORIGIN: process.env.ORIGIN
  };
}

export default class Api {
  tokenClient;

  async init() {
    await gapi.client.init({
      apiKey: window.BULK_DOWNLOADER_CREDENTIALS.API_KEY,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
    })

    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: window.BULK_DOWNLOADER_CREDENTIALS.CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly',
      callback: ''
    });
  }

  handleAuth(callback) {
    this.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw (response);
      }
      await callback.call();
    };

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({prompt: ''});
    }
  }

  handleLogout() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken('');
    }
  }

  signedIn() {
    return gapi.client.getToken() !== null
  }
}
