import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoaders } from '../selectors';
import { loadLiteracyToolboxPage } from '../actions';
import LiteracyToolboxContainer from './literacy_toolbox/LiteracyToolboxContainer';
import TopNavBar from './nav/TopNavBar';
import { Link, useLocation } from 'react-router-dom';
import SkipToContent from '../components/nav/SkipToContent';
import { gaPageView } from '../utils/analytics';
import { useBodyBackgroundTyped, useDocumentTitle } from '../hooks';
import backgroundImage from '../../assets/images/bg/G09.jpg';
import backgroundImagePreload from '../../assets/images/bg/preload/G09.jpg';

const title = 'Literacy Toolbox';

const LiteracyToolboxPage = ({ isLoading, loadLiteracyToolboxPage, match }) => {
  useDocumentTitle(`ODELL - ${title}`);
  useBodyBackgroundTyped([backgroundImagePreload, backgroundImage], false);

  useEffect(() => {
    loadLiteracyToolboxPage(match.params);
    gaPageView(title);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const backTo = location.state?.backTo || '/';

  return (
    <div>
      <SkipToContent />
      <div className="o-od-sticky o-od-sticky--top">
        <TopNavBar />
        <div className="o-od-subheader--grade o-od-page__wrap row u-od-cs-bg--page-header">
          <div className="columns small-12 u-od-flex-row align-middle">
            <Link className="o-od-subheader__back" to={backTo}>
              <i
                className="u-od-txt--icon-large od-icon-chevron-left"
                aria-hidden="true"
              ></i>
            </Link>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
      <main
        id="main"
        className="o-od-page__wrap row u-od-cs-bg--page-content o-od-sticky__container--grade u-od-margin-bottom--huge"
      >
        <div className="columns c-od-static-page u-od-padding-top--base u-od-padding-bottom--huge">
          {!isLoading && <LiteracyToolboxContainer />}
        </div>
      </main>
    </div>
  );
};

LiteracyToolboxPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  // actions
  loadLiteracyToolboxPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: getLoaders(state, ['LITERACY_TOOLBOX']),
  };
};

export default connect(mapStateToProps, { loadLiteracyToolboxPage })(
  LiteracyToolboxPage
);
