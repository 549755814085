import callWithRetry from '../call_with_retry';
import delay from '../delay';

const DELAY = 1300;

export default class GDriveCopier {
  static copy(folder, parent) {
    return new Promise((resolve, reject) => {
      GDriveCopier.gApiCopyFolder(folder, parent).then(response => {
        const target = response.result;
        const promises = [];

        folder.children.forEach((child, i) => {
          if (child.children) {
            promises.push(
              delay(() => {
                return GDriveCopier.copy(child, target);
              }, (i + 1) * DELAY)
            );
          } else {
            promises.push(
              delay(() => {
                return GDriveCopier.gApiCopyFile(child, target).then(() => {
                  console.log(`Copied file ${child.name}`);
                }, reject);
              }, (i + 1) * DELAY)
            );
          }
        });

        Promise.all(promises).then(() => {
          resolve();
          console.log(`Copied folder ${folder.name}`);
        }, reject);
      }, reject);
    });
  }

  static gApiCopyFolder(folder, parent) {
    return callWithRetry(
      () => {
        return gapi.client.drive.files.create({
          resource: {
            name: folder.name,
            mimeType: 'application/vnd.google-apps.folder',
            parents: parent.id ? [parent.id] : null
          },
          fields: 'id, name'
        });
      },
      { folderName: folder.name }
    );
  }

  static gApiCopyFile(file, target) {
    return callWithRetry(
      () => {
        return gapi.client.drive.files.copy({
          fileId: file.id,
          resource: {
            name: file.name,
            parents: [target.id]
          },
          fields: 'id, name'
        });
      },
      { fileName: file.name }
    );
  }
}
