import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import { linkWithParams } from 'routes';
import {
  thumbnailUrlWithPreload,
  thumbnailHoverUrlWithPreload,
} from 'utils/Formatters';
import I18n from 'utils/i18n.js.erb';

const TEACHER_RESOURCES = ['integrated_units', 'adaptation_resources'];

const getLinkToBlock = g => {
  return g.id ? { pathname: linkWithParams('grade', g.hierarchy) } : '#';
};

// NOTE: comment this for now, we may need at next iteration
export const teacherResources = () => {
  return map(TEACHER_RESOURCES, r => {
    return (
      <li key={`g-${r}`} className="medium-6 small-12">
        <Link
          className="u-od-custom-link o-od-card-grade c-od-home__resource u-od-cs-bg--medium-gray"
          to={'#'}
        >
          <div className="o-od-card-grade__label u-od-cs-bg--light">
            <h2 className="u-od-txt--home-label">{I18n.t(`ui.home.${r}`)}</h2>
          </div>
        </Link>
      </li>
    );
  });
};

const HomeItems = ({ data }) => {
  if (isEmpty(data.grades)) return null;

  const blocks = map(data.grades, g => {
    const link = getLinkToBlock(g);
    return (
      <li key={`g-${g.id}`} className="medium-6 small-12">
        <Link
          className="u-od-custom-link o-od-card-grade c-od-home__grade"
          to={link}
          style={{
            '--thumb-image': thumbnailUrlWithPreload(g),
            '--hover-thumb-image': thumbnailHoverUrlWithPreload(g),
          }}
        >
          <div className="o-od-card-grade__label u-od-cs-bg--light">
            <h2 className="u-od-txt--home-label">{g.title}</h2>
          </div>
        </Link>
      </li>
    );
  });

  return <ul className="o-od-card-grade__wrap u-od-flex-row">{blocks}</ul>;
};

HomeItems.propTypes = {
  isStudent: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

export default HomeItems;
