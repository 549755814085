import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import SubHeader from './nav/SubHeader';
import SubNavBar from './nav/SubNavBar';
import TopNavBar from './nav/TopNavBar';
import SkipToContent from '../components/nav/SkipToContent';
import Error from '../components/Error';
import { useDocumentTitle, useBodyBackgroundTyped } from '../hooks';

const Layout = ({
  backgroundUrls,
  children,
  guidebookType,
  resourceType,
  title,
  withSubNavBar,
  withTopBar,
}) => {
  const [height, setHeight] = useState(null);
  const onResize = useCallback(
    (_width, height) => setHeight(height),
    [setHeight]
  );
  useDocumentTitle(title);
  useBodyBackgroundTyped(backgroundUrls, resourceType === 'activity');

  return (
    <div>
      <SkipToContent />
      <div className="o-od-sticky o-od-sticky--top">
        {withTopBar && <TopNavBar resourceType={resourceType} />}
        <SubHeader resourceType={resourceType} />
        {withSubNavBar && <SubNavBar resourceType={resourceType} />}
        <ReactResizeDetector handleHeight onResize={onResize} />
      </div>
      <main
        id="main"
        className={`o-od-page__wrap row u-od-cs-bg--page-content o-od-sticky__container--${resourceType} o-od-sticky__container--${guidebookType} u-od-margin-bottom--huge`}
        style={{ marginTop: height }}
      >
        <Error />
        {children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  backgroundUrls: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.object,
  guidebookType: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withSubNavBar: PropTypes.bool.isRequired,
  withTopBar: PropTypes.bool.isRequired,
};

const isLoading = (prevProps, nextProps) => {
  return !nextProps.isLoaded;
};

export default React.memo(Layout, isLoading);
