import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { kebabCase, map } from 'lodash';
import { linkWithParams } from 'routes';

export const getLevelList = (location, active, data, callback = null) => {
  function hideBreadbcrumb(e) {
    const dropDown = e.target.closest('ul.o-od-dropdown-menu');
    window.setTimeout(() => {
      if (dropDown && !dropDown.contains(document.activeElement)) {
        callback();
      }
    }, 10);
  }

  return map(data, r => {
    const cls = classNames({
      'u-od-cs-bg--page-nav': r.id !== active.id,
      'o-od-breadcrumb--active u-od-cs-bg--dark-purple u-od-cs-txt-link--light':
        r.id === active.id,
    });
    const pathname = linkWithParams(r.odellCurriculumType, r.hierarchy);
    const title =
      r.id === active.id ? r.title : `${r.odellCurriculumType[0]}${r.number}`;
    const fullTitle =
      r.id === active.id ? r.title : `${r.odellCurriculumType} ${r.number}`;

    return (
      <li key={`bc-${kebabCase(title)}-${r.id}`} className="o-od-breadcrumb">
        <Link
          aria-label={fullTitle}
          className={cls}
          data-hover-text={fullTitle}
          onBlur={hideBreadbcrumb} // eslint-disable-line react/jsx-no-bind
          to={{ pathname, search: location.search }}
        >
          <span>{title}</span>
        </Link>
      </li>
    );
  });
};

const BreadCrumbDropdown = ({ active, data, resourceType }) => {
  const [isOpen, toggleOpen] = useState(false);
  const onMouseEnter = useCallback(() => toggleOpen(true), [toggleOpen]);
  const onMouseLeave = useCallback(() => toggleOpen(false), [toggleOpen]);

  const pathname = linkWithParams(active.odellCurriculumType, active.hierarchy);
  const title = `${active.odellCurriculumType} ${active.number}`;
  const clsDropdown = classNames(
    `o-od-dropdown-menu no-bullet u-od-cs-txt--txt-black o-od-dropdown-menu--${resourceType}`,
    { 'o-od-dropdown-menu--open': isOpen }
  );

  const location = useLocation();
  const list = getLevelList(location, active, data, onMouseLeave);

  if (active.odellCurriculumType === 'section' && active.skipSections) {
    return false;
  }

  return (
    <li
      className={`o-od-breadcrumb--dropdown o-od-breadcrumb__triangle o-od-breadcrumb__triangle--${resourceType}`}
      onMouseOver={onMouseEnter}
      onFocus={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="presentation"
    >
      <Link to={{ pathname }}>{title}</Link>
      <ul className={clsDropdown}>{list}</ul>
      <span className="o-od-breadcrumb__white-box"></span>
    </li>
  );
};

BreadCrumbDropdown.propTypes = {
  active: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default BreadCrumbDropdown;
