export default class DirectoryPicker {
  static show(accessToken, callback) {
    let picker = new google.picker.PickerBuilder()
      .setTitle('Select a folder to copy files into')
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MINE_ONLY)
      .addView(
        new google.picker.DocsView()
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setParent('root')
      )
      .setOAuthToken(accessToken)
      .setDeveloperKey(window.BULK_DOWNLOADER_CREDENTIALS.API_KEY)
      .setCallback(callback)

    if (typeof window.BULK_DOWNLOADER_CREDENTIALS.ORIGIN !== 'undefined') {
      picker = picker.setOrigin(window.BULK_DOWNLOADER_CREDENTIALS.ORIGIN);
    }

    picker.build().setVisible(true);
  }
}
