import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isStudentSubdomain } from '../../routes';
import Error from 'components/Error';
import HomeItems from './HomeItems';
import I18n from 'utils/i18n.js.erb';

const Home = ({ devise, data }) => {
  if (isEmpty(data.grades)) return null;
  return (
    <main id="main" className="o-od-sticky__container--top u-od-cs-bg--light">
      <h1 className="hidden">{I18n.t('ui.home.title')}</h1>
      <div className="row o-od-page__wrap">
        <Error />
      </div>
      <HomeItems data={data} isStudent={isStudentSubdomain(devise)} />
    </main>
  );
};

Home.propTypes = {
  data: PropTypes.object.isRequired,
  devise: PropTypes.object.isRequired,
};

export default Home;
