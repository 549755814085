import React from 'react';
import PropTypes from 'prop-types';
import { Textfit } from 'react-textfit';
import classNames from 'classnames';
import { rawHtml } from 'utils/Formatters';
import ActivityControls from './ActivityControls';

const ACTIVITY_SLIDE_TEXT_SIZE = 26;

const ActivitySlides = props => {
  const { activity, meta, segment } = props;
  const clsSlideContent = classNames('o-od-slide__content', {
    'u-od-txt--slide-fullscreen o-od-slide__content--fullscreen':
      meta.fullScreen,
    'u-od-txt--slide o-od-slide__content--base': !meta.fullScreen,
  });
  const SlideContentElement = meta.fullScreen ? Textfit : 'div';
  return (
    <div>
      <SlideContentElement
        max={ACTIVITY_SLIDE_TEXT_SIZE}
        className={clsSlideContent}
      >
        {rawHtml(segment.data)}
        {rawHtml(activity.purpose)}
      </SlideContentElement>
      <div className="o-od-slide__controls">
        <ActivityControls activity={activity} meta={meta} />
      </div>
    </div>
  );
};

ActivitySlides.propTypes = {
  activity: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  segment: PropTypes.object.isRequired,
};

export default ActivitySlides;
