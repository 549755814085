import callWithRetry from '../call_with_retry';
import delay from '../delay';
import GDriveFile from './gdrive_file';

const DELAY = 300;

export default class GDriveFolder {
  constructor(id, name) {
    this.id = id;
    this.name = name;
    this.children = [];
  }

  static load(id, name) {
    return new Promise((resolve, reject) => {
      const folder = new GDriveFolder(id, name);

      const loadChildren = name
        ? folder.gApiLoadChildren()
        : folder.gApiLoadName().then(name_ => {
            folder.name = name_;
            return folder.gApiLoadChildren();
          });

      loadChildren.then(response => {
        const children = response.result.files;

        children.forEach((child, i) => {
          if (child.mimeType === 'application/vnd.google-apps.folder') {
            folder.children.push(
              delay(() => {
                return GDriveFolder.load(child.id, child.name);
              }, (i + 1) * DELAY)
            );
          } else {
            folder.children.push(
              delay(() => {
                return GDriveFile.load(child.id, child.name).then(file => {
                  console.log(`Loaded file ${file.name}`);
                  return file;
                });
              }, (i + 1) * DELAY)
            );
          }
        });

        Promise.all(folder.children).then(children_ => {
          folder.children = children_;
          resolve(folder);
          console.log(`Loaded folder ${folder.name}`);
        }, reject);
      }, reject);
    });
  }

  static toId(url) {
    const parsed = new URL(url);

    if (url.match(/\/open/)) {
      return parsed.searchParams.get('id');
    }

    const split = parsed.pathname.split('/');
    return split[split.length - 1];
  }

  gApiLoadName() {
    return callWithRetry(() => {
      return gapi.client.drive.files
        .get({
          fileId: this.id
        })
        .then(response => {
          return response.result.name;
        });
    }, {});
  }

  gApiLoadChildren() {
    return callWithRetry(() => {
      return gapi.client.drive.files.list({
        q: `'${this.id}' in parents`
      });
    }, {});
  }
}
