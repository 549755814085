import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, filter } from 'lodash';
import TabContainer from '../common/TabContainer';

import SectionOverview from './SectionOverview';
import SectionDiagnostic from './SectionDiagnostic';
import SectionCulminatingTask from './SectionCulminatingTask';
import SectionMaterials from './SectionMaterials';
import SectionTexts from './SectionTexts';

const TAB_KEYS = [
  { key: 'overview', component: SectionOverview },
  { key: 'diagnostic', component: SectionDiagnostic },
  { key: 'culminating_task', component: SectionCulminatingTask },
  { key: 'texts', component: SectionTexts },
  { key: 'materials', component: SectionMaterials },
];

const Section = ({ resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'section')
    return null;

  const tabKeys = filter(TAB_KEYS, tab =>
    resource.last ? tab.key !== 'diagnostic' : tab.key !== 'culminating_task'
  );

  return (
    <TabContainer
      tabKeys={tabKeys}
      type="section"
      cls="o-od-panel-tab--section small-6"
      resource={resource}
    />
  );
};

Section.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Section;
