import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utils/i18n.js.erb';
import { map, startCase, toLower } from 'lodash';

const ClassroomShareButton = ({ resource, url }) => {
  useEffect(() => {
    if (
      typeof gapi === 'undefined' ||
      typeof gapi.sharetoclassroom === 'undefined'
    ) {
      window.___gcfg = { parsetags: 'explicit' };

      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/platform.js';
      document.body.appendChild(script);

      script.onload = () => {
        gapi.sharetoclassroom.go('root');
      };
    } else {
      gapi.sharetoclassroom.go('root');
    }
  });

  const title = `${resource.guidebook.title} → ${map(
    [
      resource.hierarchy.section,
      resource.hierarchy.lesson,
      resource.hierarchy.activity,
    ],
    h => startCase(toLower(h))
  ).join('→ ')}`;

  return (
    <div className="o-od-activity__classroom-share">
      <div className="o-od-activity__classroom-share__item">
        <div
          className="g-sharetoclassroom"
          data-size="32"
          data-title={title}
          data-theme="light"
          data-url={url}
        ></div>
      </div>
      <div className="o-od-activity__classroom-share__item o-od-activity__classroom-share__item--text">
        {I18n.t('ui.activity.share_to_google_classroom')}
      </div>
    </div>
  );
};

ClassroomShareButton.propTypes = {
  resource: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};

export default ClassroomShareButton;
