import * as ActionTypes from '../actions';
import * as EventTypes from '../actions/internal';

const defaultState = {
  currentPath: [],
  activityNumber: 1,
  segmentNumber: 1,
  fullScreen: false,
};

export default function meta(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.BREADCRUMB.RECEIVE:
      return { ...state, currentPath: action.payload.result.active };
    case ActionTypes.ACTIVITY.RECEIVE:
      return {
        ...state,
        activityNumber: parseInt(action.payload.activity.number, 10),
        segmentNumber: 1,
      };
    case EventTypes.CHANGE_ACTIVITY:
    case EventTypes.CHANGE_ACTIVITY_SEGMENT:
      return { ...state, ...action.payload };
    case EventTypes.SIGN_OUT:
      return defaultState;
    case EventTypes.TOGGLE_FULL_SCREEN:
      return { ...state, fullScreen: action.payload.mode };
    default:
      return state;
  }
}
