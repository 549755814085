/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import { camelizeKeys } from 'humps';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
import 'styles/odell.scss';

import './vendor/modernizrFlexbox';

const loadApp = () => {
  if (!(Modernizr.flexbox && Modernizr.flexwrap)) return;

  let rootElement = document.getElementById('root');

  if (rootElement) {
    const props = camelizeKeys(JSON.parse(rootElement.dataset.props));
    const history = createBrowserHistory();
    const store = configureStore(history, props);
    store.runSaga(rootSaga);

    if (process.env.NODE_ENV !== 'production') {
      import('@axe-core/react').then(axe => {
        axe.default(React, ReactDOM, 1000);
        ReactDOM.render(<Root store={store} history={history} />, rootElement);
      });
    } else {
      ReactDOM.render(<Root store={store} history={history} />, rootElement);
    }
  }
};

if (
  document.readyState === 'complete' ||
  (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
  loadApp();
} else {
  document.addEventListener('DOMContentLoaded', loadApp);
}
