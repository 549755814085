import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import I18n from 'utils/i18n.js.erb';
import { linkWithParams } from 'routes';
import { rawHtml } from 'utils/Formatters';

const LessonCard = ({ resource }) => {
  return (
    <li className="o-od-card u-od-cs-bg--light u-od-custom-link o-od-item-card small-12">
      <Link to={{ pathname: linkWithParams('lesson', resource.hierarchy) }}>
        <div className="row is-collapse-child align-middle align-justify">
          <h3 className="u-od-txt--card-header">
            {I18n.t('ui.hierarchy.lesson', { num: resource.number })}
          </h3>
          <div className="o-od-item-card__label u-od-txt--card-type text-center u-od-cs-bg--dark-blue u-od-cs-txt--light">
            {resource.lessonType}
          </div>
        </div>
        {rawHtml(resource.description, 'u-od-txt--card u-od-margin-top--small')}
      </Link>
    </li>
  );
};

LessonCard.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonCard;
