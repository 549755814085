import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/i18n.js.erb';
import iconMaterials from '../../assets/images/icons/materials.svg';
import Api from '../vendor/bulk-downloader/api';
import DirectoryPicker from '../vendor/bulk-downloader/models/directory_picker';
import GDriveFolder from '../vendor/bulk-downloader/models/gdrive_folder';
import GDriveCopier from '../vendor/bulk-downloader/models/gdrive_copier';
import { staticRoutes } from '../routes';
import { Link } from 'react-router-dom';

const STATUS_COMPLETE = 'complete';
const STATUS_ERROR = 'error';
const STATUS_IDLE = 'idle';
const STATUS_IN_PROGRESS = 'in_progress';

const statusText = {
  complete: I18n.t('ui.google_bulk_downloader.status.complete'),
  error: I18n.t('ui.google_bulk_downloader.status.error'),
  idle: I18n.t('ui.google_bulk_downloader.status.idle'),
  in_progress: I18n.t('ui.google_bulk_downloader.status.in_progress'),
};

class GoogleBulkDownloader extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      status: STATUS_IDLE,
    };
  }

  init = e => {
    e.preventDefault();

    gapi.load('client', () => {
      const api = new Api();

      api.init().then(() => {
        const startOperation = _ => {
          const errorHandler = error => {
            this.setState({ status: STATUS_ERROR });
            console.error('Caught error at top level:'); // eslint-disable-line no-console
            console.error(error); // eslint-disable-line no-console
          };

          gapi.load('picker', () => {
            const callback = data => {
              if (data.action !== google.picker.Action.PICKED) {
                return;
              }

              this.setState({ status: STATUS_IN_PROGRESS });

              GDriveFolder.load(GDriveFolder.toId(this.props.url))
                .then(folder => {
                  GDriveCopier.copy(folder, { id: data.docs[0].id })
                    .then(() => {
                      // message.textContent = 'Done!';
                      this.setState({ status: STATUS_COMPLETE });
                    })
                    .catch(errorHandler);
                })
                .catch(errorHandler);
            };

            DirectoryPicker.show(gapi.client.getToken().access_token, callback);
          });
        };

        if (api.signedIn()) {
          startOperation();
        } else {
          api.handleAuth(() => {
            startOperation();
          });
        }
      });
    });
  };

  render() {
    return (
      <table className="o-od-textlist u-od-txt--materials u-od-margin-bottom--large">
        <thead>
          <tr>
            <th>{I18n.t('ui.table.materials.header.links')}</th>
            <th>{I18n.t('ui.table.materials.header.title')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="o-od-textlist__icon text-center">
              <button
                onClick={this.init}
                title={statusText[this.state.status]}
                className="u-od-txt--material--download"
              >
                <svg
                  className="o-od-icon-image o-od-icon-image--material"
                  aria-label={statusText[this.state.status]}
                  role="img"
                >
                  <title>{statusText[this.state.status]}</title>
                  <use
                    className="o-od-icon-image--visible"
                    href={`${iconMaterials}#icon-gdrive`}
                  />
                  <use
                    className="o-od-icon-image--hover"
                    href={`${iconMaterials}#icon-hover`}
                  />
                </svg>
              </button>
            </td>
            <td>
              <span>{statusText[this.state.status]}</span>
              <p className="u-od-txt--materials-note">
                <Link to={staticRoutes.about}>
                  {I18n.t('ui.google_bulk_downloader.note')}
                </Link>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default GoogleBulkDownloader;
