import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, map, sortBy, lowerCase } from 'lodash';
import {
  materialIcon,
  MATERIAL_DOWNLOAD_ICONS,
  renderNoMaterials,
} from '../common/TextMaterialHelpers';
import { materialLinks } from '../common/MaterialsList';

const GOOGLE_DOC_RE = /^https?:\/\/docs.google.com\/document/;
const GOOGLE_DOC_PDF_RE = /^https?:\/\/drive.google.com\/file/;
const PDF_RE = /\.pdf$/;

const getFileType = url => {
  if (url.match(GOOGLE_DOC_RE)) {
    return 'gdoc';
  } else if (url.match(GOOGLE_DOC_PDF_RE) || url.match(PDF_RE)) {
    return 'pdf';
  } else {
    return 'ua';
  }
};

const generalDownloads = materials => {
  const materialsCount = materials.length;
  const withPlaceholder = materialsCount % 2 !== 0;
  let materialBlocks = map(sortBy(materials, [m => lowerCase(m.title)]), m => {
    return (
      <div
        key={`mlg-${m.id}`}
        className={'o-od-textlist__literacy-toolbox-item'}
      >
        <div className="o-od-textlist__icon o-od-textlist__literacy-toolbox-item-icon">
          <p className="u-od-txt--materials__links">{materialLinks(m)}</p>
        </div>
        <div>
          <span>{m.title}</span>
        </div>
      </div>
    );
  });
  if (withPlaceholder) {
    materialBlocks.push(materialPlaceholder(materialsCount + 1));
  }
  return materialBlocks;
};

const materialPlaceholder = idx => {
  return (
    <div
      key={`mlg-${idx}`}
      className={
        'o-od-textlist__literacy-toolbox-item o-od-textlist__literacy-toolbox-item--empty'
      }
    >
      <div className="o-od-textlist__icon o-od-textlist__literacy-toolbox-item-icon">
        <p className="u-od-txt--materials__links">&nbsp;</p>
      </div>
      <div>
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

const preCreatedDownloads = materials => {
  const materialsCount = materials.length;
  const withPlaceholder = materialsCount % 2 !== 0;
  let materialBlocks = map(materials, (data, idx) => {
    const iconTitle = `Open ${data.title}`;
    const fileType = getFileType(data.url);
    const iconDef = get(MATERIAL_DOWNLOAD_ICONS, fileType);
    if (data.url) {
      const link = (
        <li key={`mlg-${idx}-${fileType}`}>
          <a
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
            title={iconTitle}
            className="u-od-txt--material--download"
          >
            {materialIcon(iconDef, iconTitle)}
          </a>
        </li>
      );
      return (
        <div
          key={`mlg-${idx}`}
          className={'o-od-textlist__literacy-toolbox-item'}
        >
          <div className="o-od-textlist__icon o-od-textlist__literacy-toolbox-item-icon">
            <p className="u-od-txt--materials__links">{link}</p>
          </div>
          <div>
            <span>{data.title}</span>
          </div>
        </div>
      );
    }
    return null;
  });
  if (withPlaceholder) {
    materialBlocks.push(materialPlaceholder(materialsCount + 1));
  }
  return materialBlocks;
};

const LiteracyToolboxItems = ({ materials, type }) => {
  const downloads =
    type === 'tool_exemplars'
      ? preCreatedDownloads(materials)
      : generalDownloads(materials);
  if (isEmpty(downloads)) return renderNoMaterials('material');
  return (
    <div
      className={
        'o-od-textlist o-od-textlist__literacy-toolbox u-od-txt--materials u-od-margin-bottom--large'
      }
    >
      {downloads}
    </div>
  );
};

LiteracyToolboxItems.propTypes = {
  materials: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default LiteracyToolboxItems;
