import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { get, indexOf, isEmpty, map, slice, sortBy, flatten } from 'lodash';
import { ODELL_BREADCRUMB_HIERARCHY } from '../../routes';
import BreadCrumbDropdown, { getLevelList } from './BreadCrumbDropdown';
import LevelArrows from './LevelArrows';

const SubBar = ({ breadcrumb }) => {
  const location = useLocation();

  if (isEmpty(breadcrumb)) return null;
  const activePath = get(breadcrumb, 'result.active');
  const currHierarchy = slice(ODELL_BREADCRUMB_HIERARCHY, 0, activePath.length);
  const curriculumOrder = idx =>
    get(
      breadcrumb,
      idx === 0
        ? 'result.data'
        : `entities.${ODELL_BREADCRUMB_HIERARCHY[idx - 1]}.${
            activePath[idx - 1]
          }.children`,
      []
    );
  const currLevel = currHierarchy.length - 1;

  const breadCrumbs = map(currHierarchy, (b, idx) => {
    const level = get(breadcrumb, `entities.${currHierarchy[idx]}`);
    const active = get(level, `${activePath[idx]}.resource`);
    const order = curriculumOrder(idx);
    const data = sortBy(
      map(level, l => l.resource),
      l => indexOf(order, l.id)
    );
    return idx === currLevel ? (
      getLevelList(location, active, data)
    ) : (
      <BreadCrumbDropdown
        key={`bcd-${b}-${idx}`}
        active={active}
        data={data}
        resourceType={b}
      />
    );
  });
  const [previous, next] = get(breadcrumb, 'result.nav');

  return (
    <div
      className={`o-od-breadcrumb--${currHierarchy[currLevel]} o-od-breadcrumb__wrap o-od-page__wrap row u-od-cs-bg--page-content align-middle`}
    >
      <div role="navigation" className="columns">
        <ul className="o-od-breadcrumb-group row">
          {flatten(breadCrumbs)}
          <LevelArrows previous={previous} next={next} />
        </ul>
      </div>
    </div>
  );
};

SubBar.propTypes = {
  breadcrumb: PropTypes.object.isRequired,
};

export default SubBar;
