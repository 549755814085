import React from 'react';
import PropTypes from 'prop-types';
// NOTE: commenting here and below as we need to remove thumbnails. more likely temporary
// import { thumbnailUrlWithPreload } from 'utils/Formatters';
import { Link } from 'react-router-dom';
import I18n from 'utils/i18n.js.erb';

const GradeProgramResource = ({ programResource, backPath }) => {
  return (
    <li className="columns small-12 medium-6 large-3 u-od-margin-bottom--gutter2x">
      <Link
        className="o-od-card u-od-custom-link o-od-card-guidebook"
        target={
          programResource.url === '/literacy-toolbox' ? '_self' : '_blank'
        }
        rel="noopener noreferrer"
        title={
          programResource.url === '/literacy-toolbox'
            ? ''
            : I18n.t('ui.open_new_tab')
        }
        to={{
          pathname: programResource.url,
          state: { backTo: backPath },
        }}
      >
        <div className="o-od-card-guidebook__label u-od-cs-bg--light">
          <h3>{programResource.title}</h3>
        </div>
        {/*<div
          className="o-od-card-guidebook__cover"
          style={{ backgroundImage: thumbnailUrlWithPreload(programResource) }}
        ></div>*/}
      </Link>
    </li>
  );
};

GradeProgramResource.propTypes = {
  programResource: PropTypes.object.isRequired,
  backgroundImage: PropTypes.string,
  backPath: PropTypes.string,
};

export default GradeProgramResource;
