export default class GDriveFile {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static load(id, name) {
    return new Promise(resolve => {
      const file = new GDriveFile(id, name);

      // don't need to fetch more metadata right now, just return the file
      resolve(file);
    });
  }
}
