import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { linkWithParams } from 'routes';
import I18n from '../../utils/i18n.js.erb';
import { useDispatch } from 'react-redux';
import { changeActivitySegment } from 'actions/internal';

const ActivityControls = ({ activity, meta }) => {
  const dispatch = useDispatch();

  const switchSegment = useCallback(
    (direction, e) => {
      const currentNum = meta.segmentNumber;
      const targetNum = direction === 'left' ? currentNum - 1 : currentNum + 1;

      if (targetNum < 1 || targetNum > activity.segmentLength) {
        return;
      }

      dispatch(changeActivitySegment(targetNum, direction));
      e.preventDefault();
    },
    [meta, dispatch, activity]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const switchLeft = useCallback(switchSegment.bind(this, 'left'), [
    switchSegment,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const switchRight = useCallback(switchSegment.bind(this, 'right'), [
    switchSegment,
  ]);

  const showPrev = activity.nav.previous || meta.segmentNumber > 1;
  const showNext =
    activity.nav.next || meta.segmentNumber < activity.segmentLength;

  const currentUrl = useLocation().pathname;

  const prevUrl = activity.nav.previous
    ? linkWithParams(
        activity.nav.previous.odellCurriculumType,
        activity.nav.previous.hierarchy
      )
    : currentUrl;

  const nextUrl = activity.nav.next
    ? linkWithParams(
        activity.nav.next.odellCurriculumType,
        activity.nav.next.hierarchy
      )
    : currentUrl;

  return (
    <ul className="o-od-slide-control">
      <li className="shrink">
        <ul className="o-od-slide-control__items">
          <li>
            {showPrev && (
              <Link
                to={{
                  pathname: prevUrl,
                }}
                onClick={switchLeft}
              >
                <i
                  title={I18n.t('ui.activity.arrows.previous')}
                  className="u-od-txt--icon-base od-icon-chevron-left"
                  aria-hidden="true"
                ></i>
              </Link>
            )}
          </li>
        </ul>
      </li>
      <li className="shrink">
        <ul className="o-od-slide-control__items">
          <li>
            {showNext && (
              <Link
                to={{
                  pathname: nextUrl,
                }}
                onClick={switchRight}
              >
                <i
                  title={I18n.t('ui.activity.arrows.next')}
                  className="u-od-txt--icon-base od-icon-chevron-right"
                  aria-hidden="true"
                ></i>
              </Link>
            )}
          </li>
        </ul>
      </li>
    </ul>
  );
};

ActivityControls.propTypes = {
  activity: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ActivityControls;
