import * as ActionTypes from '../actions';
import * as EventTypes from '../actions/internal';

const defaultState = { grades: [], literacyToolbox: [] };

export default function nav(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.GRADES.RECEIVE:
    case ActionTypes.LITERACY_TOOLBOX.RECEIVE:
      return { ...state, ...action.payload };
    case EventTypes.SIGN_OUT:
      return defaultState;
    default:
      return state;
  }
}
