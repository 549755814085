import React from 'react';
import I18n from '../../utils/i18n.js.erb';

const SkipToContent = () => {
  function handleSkipToContentClick(e) {
    e.preventDefault();
    const element =
      document.getElementsByClassName('o-od-tabs__tab-panel--selected')[0] ||
      document.getElementById('main');
    element.tabIndex = 0;
    element.focus({
      preventScroll: true,
    });
  }

  function onClick() {}

  return (
    <div>
      <button
        onClick={onClick} // eslint-disable-line react/jsx-no-bind
        id="first-element"
        className="o-od-first-element"
      ></button>
      <button
        className="o-od-skip-nav-link"
        onClick={handleSkipToContentClick} // eslint-disable-line react/jsx-no-bind
      >
        {I18n.t('ui.nav.skip_to_the_content')}
      </button>
    </div>
  );
};

export default SkipToContent;
