import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { paramsToSlug } from '../../routes';

const Header = ({ pathLevelUp, resource, resourceType }) => {
  if (isEmpty(resource) || isNil(resource)) return null;
  if (resource.odellCurriculumType === 'grade' && resourceType !== 'grade')
    return null;

  const isHighLevelResource =
    resourceType === 'guidebook' || resourceType === 'grade';
  const isWoSubTitle =
    resourceType === 'grade' || resource.guidebookType === 'development';
  const title = resource.title;
  const subTitle = isWoSubTitle ? null : resource.question;

  const leftChevronUrl =
    resource.odellCurriculumType === 'guidebook' &&
    resource.skipSections &&
    !isHighLevelResource
      ? paramsToSlug(resource.hierarchy)
      : pathLevelUp;

  return (
    <div
      className={`o-od-subheader--${resourceType} o-od-subheader--${resource.guidebookType} o-od-page__wrap row u-od-cs-bg--page-header`}
    >
      <div className="columns small-12 u-od-flex-row align-middle">
        <Link
          className="o-od-subheader__back"
          tabIndex="0"
          aria-label="Back to previous page"
          to={leftChevronUrl}
        >
          <i
            className="u-od-txt--icon-large od-icon-chevron-left"
            aria-hidden="true"
          ></i>
        </Link>
        {isHighLevelResource && <h1>{title}</h1>}
        {!isHighLevelResource && (
          <Link to={paramsToSlug(resource.hierarchy)}>
            <h1 className="u-od-txt--h1-subheader">
              {subTitle ? `${title}: ${subTitle}` : title}
            </h1>
          </Link>
        )}
      </div>
      {isHighLevelResource && subTitle && (
        <div className="u-od-txt--h1-subheader columns small-12">
          {subTitle}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  pathLevelUp: PropTypes.string,
  resource: PropTypes.object,
  resourceType: PropTypes.string,
};

export default Header;
