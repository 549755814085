/* eslint-disable no-constant-condition */
import { take, put, call, fork, select, all } from 'redux-saga/effects';
import { isEmpty, trim, replace } from 'lodash';
import { paramsToSlug } from '../routes';
import * as api from '../services';
import * as actions from '../actions';
import * as internalActions from '../actions/internal';
import { getGrades, getDevise } from '../selectors';
import { rootAnalytics } from './analytics';

// resuable fetch Subroutine
// entity : grades | resources | breadcrumb items etc
// apiFn  : api function
// params : request params
// meta   : meta to pass into success response
function* fetchEntity(entity, apiFn, params, meta = {}) {
  yield put(entity.request(params));
  const { response, error, status } = yield call(apiFn, params);
  if (response) yield put(entity.success(response, meta));
  else {
    if (status === 401) yield put(actions.logout({ error }));
    else yield put(entity.failure(error));
  }
}

// bind Generators
export const fetchBreadcrump = fetchEntity.bind(
  null,
  actions.rsaa(actions.BREADCRUMB),
  api.fetchBreadcrump
);
export const fetchGrades = fetchEntity.bind(
  null,
  actions.rsaa(actions.GRADES),
  api.fetchGrades
);

export const fetchLiteracyToolbox = fetchEntity.bind(
  null,
  actions.rsaa(actions.LITERACY_TOOLBOX),
  api.fetchLiteracyToolbox
);
export const fetchActivity = fetchEntity.bind(
  null,
  actions.rsaa(actions.ACTIVITY),
  api.fetchActivity
);
export const fetchResource = fetchEntity.bind(
  null,
  actions.rsaa(actions.RESOURCES),
  api.fetchResources
);

function* logout(error) {
  const auth = yield select(getDevise);
  yield put(internalActions.resetStore(error));
  window.location.href = auth.logoutUrl;
}

function* loadActivity(params, meta) {
  const slug = paramsToSlug(params);
  yield call(fetchActivity, { slug }, meta);
}

function* loadBreadcrumb(params, meta) {
  // const navMeta = yield select(getMeta)
  // if (navMeta.resourceId !== params.id) {
  // }
  const slug = paramsToSlug(params);
  yield call(fetchBreadcrump, { slug }, meta);
}

// load grades unless it is cached
function* loadGrades(params, meta) {
  const slug = paramsToSlug(params);
  const grades = yield select(getGrades);
  if (isEmpty(grades)) {
    yield call(fetchGrades, { slug }, meta);
  }
}

function* loadLiteracyToolbox(params, meta) {
  const slug = paramsToSlug(params);
  yield call(fetchLiteracyToolbox, { slug }, meta);
}

function* loadResource(params, meta) {
  const slug = paramsToSlug(params);
  yield call(fetchResource, { slug }, meta);
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
// Full screen
function* watchFullScreen() {
  while (true) {
    const params = yield take(actions.LOAD_FULL_SCREEN);
    const mode = params.payload;
    let html = document.documentElement;
    if (mode) {
      html.className += ' u-od-mode--fullscreen';
    } else {
      html.className = trim(
        replace(html.className, 'u-od-mode--fullscreen', '')
      );
    }
    yield put(internalActions.toggleFullScreen(mode));
  }
}

function* watchActivityPage() {
  while (true) {
    const params = yield take(actions.LOAD_ACTIVITY_PAGE);
    yield all([
      call(loadBreadcrumb, params.payload, params.meta),
      call(loadActivity, params.payload, params.meta),
    ]);
  }
}

function* watchLogout() {
  while (true) {
    const params = yield take(actions.LOGOUT);
    yield call(logout, params.payload, params.meta);
  }
}

// Fetches common data
function* watchHomePage() {
  while (true) {
    const params = yield take(actions.LOAD_HOME_PAGE);
    yield call(loadGrades, params.payload, params.meta);
  }
}

function* watchLiteracyToolboxPage() {
  while (true) {
    const params = yield take(actions.LOAD_LITERACY_TOOLBOX_PAGE);
    yield call(loadLiteracyToolbox, params.payload, params.meta);
  }
}

// Fetches data for Resource page except lesson
function* watchResourcePage() {
  while (true) {
    const params = yield take(actions.LOAD_RESOURCE_PAGE);

    if (
      params.meta.resourceType !== 'grade' &&
      params.meta.resourceType !== 'guidebook'
    ) {
      yield all([
        call(loadBreadcrumb, params.payload, params.meta),
        call(loadResource, params.payload, params.meta),
      ]);
    } else {
      yield call(loadResource, params.payload, params.meta);
    }
  }
}

export default function* root() {
  yield all([
    fork(watchActivityPage),
    fork(watchFullScreen),
    fork(watchHomePage),
    fork(watchLiteracyToolboxPage),
    fork(watchLogout),
    fork(watchResourcePage),
    ...rootAnalytics(),
  ]);
}
