import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isStudentSubdomain, studentPath } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import TeacherNotes from './TeacherNotes';
import ActivityContent from './ActivityContent';
import ClassroomShareButton from './ClassroomShareButton';

const ActivityOverview = ({ devise, resource, meta, loadFullScreen }) => {
  const { activity, content } = resource;
  const isStudent = isStudentSubdomain(devise);

  const clsActivity = classNames('o-od-activity__common columns small-12', {
    'o-od-activity__common--wnotes': !meta.fullScreen && !isStudent,
  });
  return (
    <section className="row u-od-full-width">
      {!meta.fullScreen &&
        rawHtml(
          activity.aboutTheActivityStudent.default,
          `${clsActivity} u-od-txt--content-small u-od-margin-bottom--small`
        )}

      {!meta.fullScreen && !isStudent && resource.googleClassroom && (
        <div className="o-od-activity__teacher columns small-12">
          <ClassroomShareButton
            url={`${studentPath(devise)}`}
            resource={resource}
          ></ClassroomShareButton>
        </div>
      )}
      <div className={clsActivity}>
        <ActivityContent
          resource={resource}
          meta={meta}
          loadFullScreen={loadFullScreen}
        />
      </div>
      {!meta.fullScreen && !isStudent && (
        <div className="o-od-activity__teacher columns small-12">
          <TeacherNotes
            segmentNumber={meta.segmentNumber}
            activity={activity}
            content={content}
          />
        </div>
      )}
    </section>
  );
};

ActivityOverview.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  // actions
  loadFullScreen: PropTypes.func.isRequired,
};

export default ActivityOverview;
