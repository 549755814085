import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import { rawHtml } from '../../utils/Formatters';
import ActivityCard from './ActivityCard';

const LessonOverview = ({ isStudent, resource }) => {
  const activities = map(resource.activities, s => (
    <ActivityCard key={`sl-${s.id}`} resource={s} isStudent={isStudent} />
  ));

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.lesson.tabs.overview')}</h2>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(resource.description, 'u-od-txt--content')}
        <ol className="u-od-margin-top--large no-bullet">{activities}</ol>
      </section>
    </div>
  );
};

LessonOverview.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  resource: PropTypes.object.isRequired,
};

export default LessonOverview;
