import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import TabContainer from '../common/TabContainer';

import ActivityOverview from './ActivityOverview';
import ActivityMaterials from './ActivityMaterials';
import ActivityTexts from './ActivityTexts';
import LessonLearningGoals from '../lessons/LessonLearningGoals';

const TAB_KEYS = [
  { key: 'overview', component: ActivityOverview },
  { key: 'learning_goals', component: LessonLearningGoals },
  { key: 'texts', component: ActivityTexts },
  { key: 'materials', component: ActivityMaterials },
];

const Activity = props => {
  const { resource, ...rest } = props;
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'activity')
    return null;

  return (
    <TabContainer
      tabKeys={TAB_KEYS}
      type="activity"
      cls="small-6 large-3"
      resource={resource}
      rest={rest}
    />
  );
};

Activity.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  // actions
  loadFullScreen: PropTypes.func.isRequired,
};

export default Activity;
