import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utils/i18n.js.erb';
import GroupedTextsList from 'components/common/GroupedTextsList';

const ActivityTexts = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.activity.tabs.texts')}</h2>
      </aside>
      <section className="o-od-panel__content">
        <GroupedTextsList
          textList={resource.content.textList}
          resourceType="activity"
        />
      </section>
    </div>
  );
};

ActivityTexts.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default ActivityTexts;
