import { createSelector } from 'reselect';
import {
  get,
  isBoolean,
  isEmpty,
  filter,
  join,
  some,
  zipObject,
  split,
  compact,
  includes,
  omit,
} from 'lodash';
import { API_FAILURE } from '../actions';
import {
  ODELL_HIERARCHY,
  paramsToSlug,
  isStudentSubdomain,
  isIwuFor,
} from '../routes';

// Selector for loading indicator
export const loadersSelector = (state, filters) =>
  !state.loaders[API_FAILURE] ? filters.map(f => state.loaders[f]) : [false];
export const getLoaders = createSelector(loadersSelector, loaders =>
  some(loaders, Boolean)
);
export const getStrictLoaders = createSelector(loadersSelector, loaders => {
  return isEmpty(filter(loaders, l => isBoolean(l))) || some(loaders, Boolean);
});

// Selector for auth
export const getDevise = state => state.devise;

// Selector for grades
export const getGrades = state => state.nav.grades;

// Selector for meta
export const getMeta = state => state.meta;

// Get active path by hierarchy
export const getActivePath = createSelector(
  state => state.pathname,
  path => {
    return zipObject(ODELL_HIERARCHY, compact(split(path, '/')));
  }
);

export const getLevelUpPath = createSelector(
  state => state.resource,
  resource =>
    resource
      ? paramsToSlug(omit(resource.hierarchy, resource.odellCurriculumType))
      : ''
);

export const getIsStudentSubdomain = createSelector(getDevise, devise =>
  isStudentSubdomain(devise)
);

// Selector for guidebook
const guidebookSelector = state =>
  includes(['guidebook', 'grade'], get(state.resource, 'odellCurriculumType'))
    ? state.resource
    : get(state.resource, 'guidebook');
export const getGuidebook = createSelector(
  guidebookSelector,
  guidebook => guidebook
);

// Selector for background url
export const getBackgroundUrls = createSelector(guidebookSelector, resource =>
  get(resource, 'backgroundUrls')
);

export const getGuidebookType = createSelector(guidebookSelector, resource =>
  get(resource, 'guidebookType')
);

export const getGuidebookTitle = createSelector(guidebookSelector, resource =>
  get(resource, 'title')
);

export const getResourceSlug = createSelector(
  state => state.resource,
  resource => get(resource, 'slug')
);

// Selector for page title
export const titleSelector = state =>
  includes(['guidebook', 'grade'], get(state.resource, 'odellCurriculumType'))
    ? null
    : get(state.resource, 'title', null);

export const getTitle = createSelector(
  titleSelector,
  getGuidebookTitle,
  (guidebookTitle, title) =>
    join(compact(['ODELL', title, guidebookTitle]), ' - ')
);

export const pathSelector = state => isIwuFor(state.router.location.pathname);
export const isPathIwu = createSelector(pathSelector, isPathIwu => isPathIwu);
